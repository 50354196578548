const dataServers = {
  a: {
    name: 'Server A',
    baseUrl: process.env.REACT_APP_MIDDLEWARE_URL,
    authServer: true,
    default: true,
    uploadsContainer: '/files'
  }
  // b: {
  //   name: 'Server B',
  //   baseUrl: "https://data.carto4ch.huma-num.fr/",
  // }
};

export default dataServers;
